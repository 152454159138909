import React from 'react';
import styled, { css } from 'styled-components';

import Spacing from 'layouts/Spacing';
import MaxWidth from 'layouts/max-width';
import SlickSlider from 'components/slick-slider/SlickSlider';
import TitleAndText from 'parts/title-and-text/TitleAndText';
import PriceAndBuyBtn from './PriceAndBuyBtn';
import DefaultImage from 'images/placeholders/default-image-person.jpg';

//#region Styling

const Wrap = styled.div`
	position: relative;
	height: 100%;
	> div[role='region'] {
		height: 100%;
		min-height: 150px;
	}
	.slide,
	.singular-image {
		width: 100%;

		.lazy-image {
			position: relative;
			width: 100%;
			padding-top: 62.5%; /* 16:10 aspect ratio */
			overflow: hidden;
			${p =>
				p.theme.media.large(css`
					min-height: 480px;
				`)}
			.lazy-image__main {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				right: 0;
				margin: auto;
				height: auto;
				width: auto;
				max-height: 100%;
				max-width: 100%;
			}
		}
	}
`;

const MaxWidthWrap = styled(MaxWidth)`
	${p =>
		p.theme.media.large(css`
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		`)}
`;

const ImageWrap = styled.div`
	text-align: center;
	position: relative;
	padding-top: 20px;
	${p =>
		p.theme.media.large(css`
			width: 50%;
			padding-top: 0;
		`)}

	.slick-dots {
		li button:before {
			font-size: 10px;
			${p =>
				p.theme.media.medium(css`
					font-size: 12px;
				`)}
		}
	}
	.slick-slide {
		border-radius: ${p => p.theme.utils.borderRadius};
	}
`;

const TextWrap = styled.div`
	text-align: center;
	padding: 0 20px;
	${p =>
		p.theme.media.mediumDown(css`
			margin-top: 30px;
			padding: 0;
		`)}
	${p =>
		p.theme.media.large(css`
			width: 50%;
			padding-right: 0;
			> div {
				width: 570px;
				max-width: 100%;
				margin-left: auto;
			}
		`)}
`;

//#endregion

/**
 * Represents the hero section of a product
 * @param {object} props - The properties for the component
 * @param {object} props.product - The product data
 * @param {Array} props.sliderImages - The images for the slider
 * @returns {JSX.Element}
 */
export default function Hero({ product, sliderImages, ...props }) {
	return (
		<section className="product-hero">
			<Spacing {...props}>
				<MaxWidthWrap>
					<ImageWrap>
						<Wrap>
							<Image
								product={product}
								images={sliderImages}
								sliderThumbnails={false}
							/>
						</Wrap>
					</ImageWrap>
					<TextWrap>
						<div>
							<TitleAndText
								stickTitle={product?.smallTitle}
								title={product?.title}
								headinglevel="h1"
								isfirstheading={true}
								spacing={{ bottom: 'none' }}
								componentindex={1}
								settings={['Midtstilt tekst']}
							/>

							<PriceAndBuyBtn product={product} />
						</div>
					</TextWrap>
				</MaxWidthWrap>
			</Spacing>
		</section>
	);
}

const Image = ({ product, images, sliderThumbnails = true }) => {
	if (!images?.length > 0)
		return (
			<img
				src={DefaultImage}
				alt="Dette produktet mangler bilde for øyeblikket"
			/>
		);

	return (
		<SlickSlider
			layout="default"
			images={images}
			dots={!sliderThumbnails}
			autoplay={false}
			fade={true}
			aria-label={`Produktbilder av ${product?.smallTitle} - ${product?.title}`}
			{...(sliderThumbnails && {
				thumbnails: { show: true, count: 4 },
				fullscreenButton: { show: true, position: 'left' },
			})}
		/>
	);
};
