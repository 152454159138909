import React from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { track } from 'context/AnalyticsProvider';
import Button from 'components/forms/Button';
import PriceFull, { MonthlyPrice } from './Price';

//#region
const Wrap = styled.div`
	width: 100%;
	max-width: ${p => p.theme.widths.medium};
	margin: ${p => (p.$centered ? '0 auto' : '0')};
`;

const Inner = styled.div`
	text-align: ${p => (p.$centered ? 'center' : 'left')};
	.price-original {
		display: block;
	}

	${p =>
		p.$variant === 'default' &&
		css`
			button {
				width: 330px;
				max-width: 100%;
				justify-content: center;
			}
		`}
`;

const Desc = styled.p`
	font-style: italic;
	font-size: 14px;
	line-height: 22px;
	color: ${p => p.theme.colors.grey700};
	margin-top: 20px;
`;

const NotForSale = styled.p`
	font-weight: 500;
	font-size: 22px;
	line-height: 32px;
	margin: 0;
`;

//#endregion

export default function PriceAndBuyBtn({
	product,
	productVariant,
	centered = true,
	variant = 'default',
	stretch = false,
}) {
	return (
		<Wrap $centered={centered}>
			<Inner $centered={centered} $variant={variant}>
				{(!product?.price && !product?.campaignPrice && (
					<NotForSale>
						Dette produktet er ikke mulig å bestille for øyeblikket.
					</NotForSale>
				)) || (
					<>
						<PriceFull product={product} variant={variant} />

						<BuyButton
							product={product}
							productVariant={productVariant}
							stretch={stretch}
						/>

						{product?.priceDesc && variant !== 'simple' && (
							<Desc>{product?.priceDesc}</Desc>
						)}
						{variant !== 'simple' && (
							<MonthlyPrice product={product} />
						)}
					</>
				)}
			</Inner>
		</Wrap>
	);
}

export function BuyButton({ product, productVariant, stretch = false }) {
	if (!product && !productVariant) return null;

	return (
		<Button
			aria-label={`Gå til bestilling for ${
				productVariant?.title || product?.title
			}`}
			data-cy="continue-btn"
			stretch={stretch}
			title={'Bestill nå'}
			onClick={() => {
				track('Checkout Started', {
					name: productVariant?.title || product?.title,
					label: productVariant?.title || product?.title,
					product_id: product?.id,
					category: 'Produkter',
					value: product?.price || 0,
					quantity: 1,
					currency: 'NOK',
				});
				navigate(
					`/produkter/bestill/?produkt=${
						productVariant?.slug || product?.slug
					}`
				);
			}}>
			Bestill nå
		</Button>
	);
}
