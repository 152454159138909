import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterList } from '@fortawesome/pro-regular-svg-icons/faFilterList';

import CustomSearchBox from 'components/algolia/SearchBox';
import RefinementCheckboxes from 'components/algolia/RefinementCheckboxes';
import RangeSlider from 'components/algolia/RangeSlider';
import ToggleRefinement from 'components/algolia/ToggleRefinement';
import ScreenReaderText from 'components/screen-reader-text';

import useWindow from 'context/useWindow';
import BlankButton from 'components/forms/BlankButton';

const Wrapper = styled.div`
	width: 100%;
	@media (min-width: 1300px) {
		margin-top: 15px;
		padding-right: 30px;
		width: 20%;
	}
`;

const ToggleFiltersBtn = styled(BlankButton)`
	margin: 10px 0 10px auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: 16px;
	line-height: 26px;
	font-weight: 500;
	&:hover {
		svg {
			fill: ${p => p.theme.colors.blue800} !important;
		}
	}
	${p =>
		p.theme.media.smallOnly(css`
			margin: 0 0 35px auto;
			padding: 0;
		`)}
	@media (min-width: 1300px) {
		display: none;
	}
	svg {
		margin-right: 10px;
	}
`;

const Wrap = styled.div`
	@media (max-width: 1299px) {
		transition: all 0.2s ease-out;
		visibility: ${p => (p.$show ? 'visible' : 'hidden')};
		height: 0;
		overflow: hidden;
	}
`;

const Filters = styled.fieldset`
	${p =>
		p.theme.media.smallOnly(css`
			padding-bottom: 20px;
		`)}
`;

const Field = styled.div`
	width: 100%;
	&:not(:last-of-type) {
		margin: 0 0 60px;
	}
	${p =>
		p.theme.media.mediumDown(css`
			&:not(:last-of-type) {
				margin: 0 0 20px;
			}
		`)}
`;

const Fields = styled.div`
	display: flex;
	flex-wrap: wrap;
	@media (max-width: 1300px) and (min-width: 767px) {
		margin: 0 -25px;
	}
	${Field} {
		@media (max-width: 1300px) and (min-width: 1100px) {
			flex: 1 1 calc((100% / 4) - 50px);
			margin: 0 25px 25px;
		}
		${p =>
			p.theme.media.mediumOnly(css`
				flex: 1 1 calc((100% / 2) - 50px);
				margin: 0 25px 25px;
				&:nth-last-of-type(-n + 2) {
					margin-bottom: 10px;
				}
			`)}
	}
`;

export default function ProductFilters({ query = '' }) {
	const { windowWidth } = useWindow();
	const [showFilters, setShowFilters] = useState(false);
	const [searching, setSearching] = useState(false);
	const wrapRef = useRef(null);
	const filtersRef = useRef(null);
	const toggleBtnLabel = showFilters ? 'Skjul filtrering' : 'Se filtrering';

	useEffect(() => {
		if (wrapRef && filtersRef) {
			if (!showFilters) {
				wrapRef.current.style['height'] =
					windowWidth > 1299 ? 'auto' : '0px';
			} else {
				const sectionListHeight = filtersRef.current.offsetHeight;
				wrapRef.current.style['height'] =
					windowWidth > 1299 ? 'auto' : sectionListHeight + 'px';
			}
		}
	}, [wrapRef, filtersRef, showFilters, windowWidth]);

	return (
		<Wrapper>
			<ToggleFiltersBtn
				aria-expanded={showFilters}
				aria-controls="product-archive-filters"
				aria-label={toggleBtnLabel}
				onClick={() => {
					setShowFilters(!showFilters);
				}}
				title={toggleBtnLabel}>
				<FontAwesomeIcon icon={faFilterList} />

				<span>
					{showFilters ? 'Skjul filtrering' : 'Filtrer produkter'}
				</span>
			</ToggleFiltersBtn>

			<Wrap
				$show={showFilters}
				id="product-archive-filters"
				ref={wrapRef}
				aria-hidden={showFilters === false}>
				<Filters ref={filtersRef}>
					<Fields>
						<Field>
							<ScreenReaderText OuterWrapper="legend">
								Søk eller velg kategori for å finne spesifikke
								produkt
							</ScreenReaderText>

							<CustomSearchBox
								placeholder="Søk ..."
								searching={searching}
								setSearching={setSearching}
								location="Product Archive"
							/>
						</Field>

						<Field>
							<RefinementCheckboxes
								id="product-category-checkboxes"
								label="Kategorier"
								attribute="category"
								showMore={true}
							/>
						</Field>

						<Field>
							<RefinementCheckboxes
								id="product-brand-checkboxes"
								label="Varemerke"
								attribute="brand"
								showMore={true}
							/>
						</Field>

						<Field>
							<RangeSlider
								id="product-price-range"
								attribute="finalPrice"
								title="Minimum- og makspris"
								min={1000}
								max={50000}
							/>
						</Field>

						<Field>
							<ToggleRefinement
								id="on-campaign"
								attribute="campaign"
								label="Kun kampanjeprodukter"
								title="Produkter på salg"
							/>
						</Field>
					</Fields>
				</Filters>
			</Wrap>
		</Wrapper>
	);
}
