import React from 'react';
import styled, { css } from 'styled-components';

import { calculateDownPaymentAmount, priceFormatting } from 'libs/content';
import BlankButton from 'components/forms/BlankButton';
import IconArrowDown from 'images/icons/ArrowDown';

//#region
const OriginalPrice = styled.span`
	color: ${p => p.theme.colors.grey900};
	${p =>
		p.sale === 'true' &&
		css`
			text-decoration: line-through;
			margin-right: 7.5px;
			color: ${p => p.theme.colors.grey900};
			opacity: 0.3;
			margin-bottom: 15px;
			${p =>
				p.theme.media.XSmallOnly(css`
					display: block;
				`)}
		`}
`;

const Price = styled.p`
	margin: 0 0 20px;
	font-size: 22px;
	line-height: 30px;
	font-weight: 500;
	${p =>
		p.theme.media.medium(css`
			font-size: 24px;
			line-height: 31px;
		`)}

	${p =>
		p.variant === 'simple' &&
		css`
			font-weight: 600;
			font-size: 32px;
			line-height: 54px;
			margin: 0 0 10px;
			${OriginalPrice}, ${CampaignPrice} {
				font-weight: 600;
				font-size: 32px;
				line-height: 54px;
			}
		`};
`;

const Vat = styled.small`
	font-weight: 500;
	font-size: 17px;
	line-height: 46px;
`;

const Monthly = styled.small`
	font-size: 17px;
	font-weight: 400;
	display: block;
`;

const CampaignPrice = styled.span`
	color: ${p => p.theme.colors.grey900};
	small {
		font-size: 17px;
		font-weight: 500;
	}
`;

const DownpaymentText = styled.div`
	margin-top: 60px;
	${p =>
		p.theme.media.mediumOnly(css`
			margin-top: 40px;
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: 20px;
		`)}
	p {
		margin: 0;
	}
	button {
		svg {
			margin-left: 10px;
		}
	}
`;

//#endregion

export default function PriceFull({
	product,
	productVariant,
	monthly = 'false',
	variant = 'default',
	...props
}) {
	if (!product) return '';

	if (productVariant) product = productVariant;

	if (variant === 'simple') {
		// Define string if mva is included in price or not
		const mva = product?.mva === 'Ekskludert' ? 'eks. mva' : 'inkl. mva';

		return (
			<div {...props}>
				<Price variant={variant}>
					<OriginalPrice
						sale={(product?.campaignPrice && 'true') || 'false'}>
						{product?.price &&
							priceFormatting(
								product?.price,
								'NOK',
								'en-GB',
								false
							)}{' '}
						{!product?.campaignPrice && <Vat>kr {mva}</Vat>}
					</OriginalPrice>
					{product?.campaignPrice && (
						<CampaignPrice>
							{product?.campaignPrice &&
								priceFormatting(
									product?.campaignPrice,
									'NOK',
									'en-GB',
									false
								)}{' '}
							<Vat>{mva}</Vat>
						</CampaignPrice>
					)}
				</Price>
			</div>
		);
	}

	return (
		<div {...props}>
			<Price variant={variant}>
				<OriginalPrice
					sale={(product?.campaignPrice && 'true') || 'false'}>
					{product?.price && priceFormatting(product?.price)}
				</OriginalPrice>
				{product?.campaignPrice && (
					<CampaignPrice>
						{product?.campaignPrice &&
							priceFormatting(product?.campaignPrice)}
					</CampaignPrice>
				)}{' '}
				ferdig montert
				{monthly === 'true' &&
					product?.paymentMethods?.includes('Avbetaling (3 år)') && (
						<Monthly>
							(eller {getMonthlyPrice(product, productVariant)}{' '}
							kr/mnd)
						</Monthly>
					)}
			</Price>
		</div>
	);
}

export function MonthlyPrice({ product, productVariant, ...props }) {
	if (!product) return;

	if (!product?.paymentMethods?.includes('Avbetaling (3 år)')) return;

	if (productVariant) product = productVariant;

	return (
		<DownpaymentText {...props}>
			<p>
				Avbetaling: {getMonthlyPrice(product, productVariant)} kr/mnd
				for strømkunder av NTE.
			</p>

			<BlankButton href="#avbetaling">
				<span>Les mer om avbetaling</span>
				<IconArrowDown width="12.75" height="17" />
			</BlankButton>
		</DownpaymentText>
	);
}

function getMonthlyPrice(product, productVariant) {
	if (!product || !product.paymentMethods?.includes('Avbetaling (3 år)'))
		return;

	const finalProduct = productVariant || product;
	const finalPrice = finalProduct.campaignPrice || finalProduct.price;

	return calculateDownPaymentAmount(finalPrice, 36);
}
